import React from "react"
import { Grid, Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { LinkedIn, GitHub, Twitter } from "@material-ui/icons"
import bryce from "../../assets/images/brycecampbell.jpg"
import TeamMember from "./components/TeamMember"
import jon from "../../assets/images/jonhansen.jpg"
import PortraitIcon from "@material-ui/icons/Portrait"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
const team = [
  {
    name: "Bryce Campbell",
    role: "CEO | Developer",
    image: bryce,
    social: [
      {
        name: "LinkedIn",
        icon: <LinkedIn style={{ color: "#fff" }} />,
        link: "https://www.linkedin.com/in/bryceacampbell7/",
      },
      {
        name: "Github",
        icon: <GitHub style={{ color: "#fff" }} />,
        link: "https://github.com/Bryceacampbell",
      },
      {
        name: "Portfolio",
        icon: <Twitter style={{ color: "#fff" }} />,
        link: "https://twitter.com/BryceACampbell",
      },
    ],
  },
  {
    name: "Jonathan Hansen",
    role: "CTO | Developer",
    image: jon,
    social: [
      {
        name: "LinkedIn",
        icon: <LinkedIn style={{ color: "#fff" }} />,
        link: "https://www.linkedin.com/in/jon-hansen-dev/",
      },
      {
        name: "Github",
        icon: <GitHub style={{ color: "#fff" }} />,
        link: "https://github.com/JonathanHansen98",
      },
      {
        name: "Portfolio",
        icon: <PortraitIcon style={{ color: "#fff" }} />,
        link: "https://jonhansen.dev/",
      },
    ],
  },
  {
    name: "Stack Overflow",
    role: "Resource Master",
    image: bryce,
    social: [
      {
        name: "LinkedIn",
        icon: <LinkedIn style={{ color: "#fff" }} />,
        link: "https://www.linkedin.com/in/bryceacampbell7/",
      },
      {
        name: "Github",
        icon: <GitHub style={{ color: "#fff" }} />,
        link: "https://github.com/Bryceacampbell",
      },
      {
        name: "Portfolio",
        icon: <PortraitIcon style={{ color: "#fff" }} />,
        link: "https://twitter.com/BryceACampbell",
      },
    ],
  },
]

const useStyles = makeStyles(theme => ({
  paddingY: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "3rem 0rem",
    },
  },
}))

const TeamSection = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query MyQuery {
      bryce: file(relativePath: { eq: "brycecampbell.jpg" }) {
        id
        childImageSharp {
          fixed(width: 250) {
            src
            srcSet
            ...GatsbyImageSharpFixed
          }
        }
      }
      jon: file(relativePath: { eq: "jonhansen.jpg" }) {
        id
        childImageSharp {
          fixed(width: 250) {
            src
            srcSet
            ...GatsbyImageSharpFixed
          }
        }
      }
      stackOverflow: file(relativePath: { eq: "brycecampbell.jpg" }) {
        id
        childImageSharp {
          fixed(width: 250) {
            src
            srcSet
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  console.log(data)
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.paddingY}>
            <Typography variant="h3">the webfluent</Typography>
            <Typography
              variant="h3"
              gutterBottom
              style={{ color: "rgb(37,137,124)" }}
            >
              team
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justify="space-evenly"
            style={{ textAlign: "center" }}
          >
            {team.map((member, ind) => (
              <Grid key={ind} item xs={8} md>
                <TeamMember
                  animDelay={ind * 0.5}
                  key={ind * -1}
                  member={member}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TeamSection

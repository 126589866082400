import React from "react"
import { Grid, Box, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  iconBox: {
    background: "rgb(37,137,124)",
    borderRadius: 5,
    width: "2rem",
    height: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "2rem",
    },
  },
}))
const anchorRef = React.createRef()
const IconBox = ({ social }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={2} style={{ padding: 10 }}>
        <Box className={classes.iconBox}>
          <Tooltip title={social.name}>
            <a
              ref={anchorRef}
              href={social.link}
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", display: "contents" }}
            >
              {social.icon}
            </a>
          </Tooltip>
        </Box>
      </Grid>
    </>
  )
}

export default IconBox

import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import IconBox from "./IconBox/IconBox"
import { motion, useAnimation } from 'framer-motion'
import useInView from 'react-cool-inview'
const useStyles = makeStyles(theme => ({
  teamImage: {
    width: "15rem",
    borderRadius: "50%",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);"
  },
  iconBox: {
    background: "rgb(37,137,124)",
    borderRadius: 5,
  },
}))

const imgVariants = {
  open: {
    x: 0,
    opacity: 1

  }, closed: {
    x: 100,
    opacity: 0
  }
}
const titleVariants = {
  open: {
    y: 0,
    opacity: 1

  }, closed: {
    y: -50,
    opacity: 0
  }
}


const TeamMember = ({ member, animDelay }) => {
  const classes = useStyles()
  const controls = useAnimation()

  const delayMs = animDelay * 1000
  const { ref } = useInView({
    onEnter: () => {
      setTimeout(controls.start('open'), delayMs)
    }
  })
  return (
    <div>
      <motion.img
        ref={ref}
        variants={imgVariants}
        animate={controls}
        initial='closed'
        src={member.image}
        alt={member.name}
        className={classes.teamImage}
        transition={{ duration: .6, delay: animDelay }} />

      <motion.div transition={{ delay: .6 + animDelay, duration: .5 }} variants={titleVariants} animate={controls} initial='closed'>
        <Typography variant="h5" gutterBottom>
          {member.name}
        </Typography>
      </motion.div>

      <motion.div transition={{ delay: .9 + animDelay, duration: .5 }} variants={titleVariants} animate={controls} initial='closed'>
        <Typography variant="h6" gutterBottom>
          {member.role}
        </Typography>
      </motion.div>

      <Grid container justify="center">
        {member.social
          ? member.social.map((social, ind) => (
            <motion.div variants={titleVariants} animate={controls} transition={{ delay: ((ind * .3) + .9) + animDelay, type: 'spring', damping: 10, duration: .5 }} initial='closed' >
              <IconBox key={ind} social={social} />
            </motion.div>
          ))
          : null}
      </Grid>
    </div>
  )
}

export default TeamMember

import React from "react"
import { Container } from "@material-ui/core";
import Layout from "../components/Layout/layout"
import Hero from "../components/Hero/Hero"
import heroImg from '../assets/images/hero.jpeg';
import Squares from '../components/Squares/Squares'
import { WaveDiv } from "../components/WaveDiv/WaveDiv"
import PageInfoSection from '../components/PageInfoSection/PageInfoSection';
import TeamSection from '../components/TeamSection/TeamSection';
import AboutWaveSection from '../components/AboutWaveSection/AboutWaveSection';

const AboutPage = () => (
  <Layout>
    <Hero image={heroImg} text={"home page image"} />
    <Squares>
      <Container>
        <PageInfoSection
          title="about"
          blueTitle="webfluent"
          text="Our passion is you, the client. We want to help your business reach its full potential. At Webfluent, you can expect:
          "
          list={[
            '- A unique product embedded with effective digital marketing.',
            '- Consistent support and communication before, during, and after your product is delivered.',
            '- An effective, aesthetically pleasing, easy-to-use product.']}
        >
          {/* render children */}
        </PageInfoSection>
      </Container>
    </Squares>

    <WaveDiv>
      <AboutWaveSection />
    </WaveDiv>

    <Squares>
      <TeamSection />
    </Squares>

  </Layout>
);

export default AboutPage;
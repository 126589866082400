import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid } from "@material-ui/core"
import TopWave from './components/TopWave'
import BottomWave from './components/BottomWave'
const useStyles = makeStyles({
  middleDiv: {
    margin: "-7px 0 -1px 0",
    backgroundColor: "#beddda",
  }
})

export const WaveDiv = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <TopWave />
      <section className={classes.middleDiv}>
        <Container>
          {children}
        </Container>
      </section>
      <BottomWave />
    </>
  )
}

import React from 'react'
import { variants } from './variants'
import { motion } from "framer-motion"
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
const useStyles = makeStyles({
    svg: {
        fill: "#beddda",
    },
    bottomSvg: {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
    }

}
)
const BottomWave = () => {
    const classes = useStyles();
    return (
        <svg width="100%" viewBox="0 0 1365 130.92" className={clsx(classes.svg, classes.bottomSvg)}>
            <motion.path
                transition={{ flip: Infinity, duration: 25 }}
                variants={variants}
                animate="animate"
            />
        </svg >
    )
}
export default BottomWave

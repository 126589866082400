import React from "react"
import { Grid, Typography, Button, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
const useStyles = makeStyles(theme => ({
  paddingY: {
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem 0rem",
    },
  },
  paragrapgh: {
    lineHeight: "2",
  },
  buttonLeft: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginRight: theme.spacing(1),
    },
  },
  buttonRight: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginLeft: theme.spacing(1),
    },
  },
}))

const AboutWaveSection = () => {
  const classes = useStyles()
  const { image, mobile } = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "phone-mockup.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mobile: file(relativePath: {eq: "phone-mockup.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(image.childImageSharp.fixed.src)
  return (
    <Grid container alignItems="center" spacing={4}>
      <Grid item xs={12} md={6}>
        <Hidden mdDown>
          <Img fixed={image.childImageSharp.fixed} />
        </Hidden>
        <Hidden lgUp>
          <Img fluid={mobile.childImageSharp.fluid} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className={classes.paddingY}>
          <Typography
            style={{ fontFamily: "Cabin", fontStyle: "italic" }}
            variant="h3"
          >
            we build
          </Typography>
          <Typography
            variant="h3"
            style={{
              color: "rgb(37,137,124)",
              fontFamily: "Cabin",
              fontStyle: "italic",
            }}
          >
            responsive
          </Typography>
          <Typography
            variant="h3"
            gutterbottom
            style={{ fontFamily: "Cabin", fontStyle: "italic" }}
          >
            websites
          </Typography>
        </div>
        <Typography className={classes.paragraph} variant="body2" gutterBottom>
          At Webfluent we take the utmost pride in our work. Your priorities are our priorities. We are constantly updating and improving our processes to provide the best experience for you and the users of your app/website. If we didn’t build it, we can fix it.
        </Typography>
        <br></br>
        <div className={classes.paddingY}>
          <Button className={classes.buttonLeft}>Contact Us</Button>
          <Button
            className={classes.buttonRight}
            style={{ background: "#fff", color: "#000000CC" }}
          >
            {" "}
            View Our Work
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default AboutWaveSection
